/* assignable icons */

.icon-externalMark {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: -webkit-linear-gradient(rgb(0, 194, 247), rgb(0, 149, 208));
  background: -moz-linear-gradient(rgb(0, 194, 247), rgb(0, 149, 208));
  background: -o-linear-gradient(rgb(0, 194, 247), rgb(0, 149, 208));
  background: linear-gradient(rgb(0, 194, 247), rgb(0, 149, 208));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00c2f7', endColorstr='#0095d0')";
}

.icon-leaveMark {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: -webkit-linear-gradient(rgb(255, 160, 75), rgb(255, 110, 14));
  background: -moz-linear-gradient(rgb(255, 160, 75), rgb(255, 110, 14));
  background: -o-linear-gradient(rgb(255, 160, 75), rgb(255, 110, 14));
  background: linear-gradient(rgb(255, 160, 75), rgb(255, 110, 14));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffa04b', endColorstr='#ff6e0e')";
}

.icon-internalMark {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: -webkit-linear-gradient(rgb(162, 124, 216), rgb(141, 70, 173));
  background: -moz-linear-gradient(rgb(162, 124, 216), rgb(141, 70, 173));
  background: -o-linear-gradient(rgb(162, 124, 216), rgb(141, 70, 173));
  background: linear-gradient(rgb(162, 124, 216), rgb(141, 70, 173));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#a27cd8', endColorstr='#8d46ad')";
}

.icon-grayMark {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: -webkit-linear-gradient(rgb(187, 187, 187), rgb(141, 141, 141));
  background: -moz-linear-gradient(rgb(187, 187, 187), rgb(141, 141, 141));
  background: -o-linear-gradient(rgb(187, 187, 187), rgb(141, 141, 141));
  background: linear-gradient(rgb(187, 187, 187), rgb(141, 141, 141));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#bbbbbb', endColorstr='#8d8d8d')";
}
