.slider {
    min-height: 25px;
    min-width: 100px;
/*    border-radius: 5px;
    background-color: #f6f6f6;*/
}

.sliderTrack {
    position: relative;
    top: 10px;
    margin: 0px 10px 0px 10px;
    height: 2px;
    border-radius: 2px;
    border: 1px solid #C0C0C0;
    background-color: #C0C0C0;
    box-shadow: rgba(255, 255, 255, 0.75) 0px 1px 0px;
}

.sliderThumbContainer {
    position: relative;
    top: -4px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    /*background-color: #fcc; */
}

.sliderThumb {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    padding: 2px 1px 1px 3px;
    /*background-color: rgb(170, 170, 170);*/
}