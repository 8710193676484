
  html, .noDefaults {
    -webkit-appearance:none;
    -webkit-touch-callout:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    -webkit-text-size-adjust:none;
    /*
    -webkit-user-select:none;
    */
  }

  body {
    margin:0px;
    padding:0px;
    z-index: inherit;
  }

  pre {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
    font-size: 13px;
    line-height: 19px;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
  }

  .selectable {
    -moz-user-select: auto;
    -khtml-user-select: auto;
    -webkit-user-select: auto;
    user-select: auto;
  }

  .debug {
    border: solid 1px hotpink;
    background-color: rgba(255,202,202,0.7);
  }

  .blockFloat {
    position:relative;
    display:block;
    float:left;
    left:0px;
    top:0px;
  }

  .blockFloatNot {
    position:relative;
    display:block;
    float:left;
    clear:left;
    left:0px;
    top:0px;
  }

  .blockFloatNotChromeFix {
    position:relative;
    display:block;
    float:left;
    left:0px;
    top:0px;
  }

  .blockFloatRight {
    position:relative;
    display:block;
    float:right;
    clear:none;
    left:0px;
    top:0px;
  }

  .blockFloatRightNot, .blockFloatNotRight
  {
    position:relative;
    display:block;
    float:right;
    clear:right;
    left:0px;
    right:0px;
  }

  /* use to accessibly hide elements, i.e. visible to screen readers only */
  .visually-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
  }
  
  .history td, .history th {
    font-size: 14px;
  }

  table {
    border-collapse: collapse;
    border: 1px solid #777;
    margin: 10px;
  }

  th, td {
    border: 1px solid #777;
    text-align: left;
    padding: 5px 10px;
  }

  #mainCon {
    position:absolute;
    margin:0px;
    padding:0px;
    width:100%;
    height: auto;
    top:0px;
    left:0px;
  }

  #mainCon.withHeader {
    margin-top:49px;
  }

  #pageHdr {
    position:fixed;
    top:0px;
    left:0px;
    min-height:49px;
    width:100%;
    background-color: #003059;
    display: none;
  }
  
.centerCont
{
  margin: 0 auto;
  margin-top: 150px;
  width: 275px;
  border: 1px solid f99;
}

.signinLogo
{
  width: 142px;
  height: 158px;
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 40px;
  cursor:pointer;
}

.ellipsis
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

.flash
{
  border-radius: 3px;
  background-color: #ffcc66;
  text-align: left;
  padding: 2px 2px 2px 2px;
  margin-left: 10px;
}

.greyHover:hover
{
  background-color: #f2f2f2;
}
