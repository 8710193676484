.croppr-container {
  height: inherit;
  width: inherit;
}

.croppr-container * {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.croppr-container img {
  vertical-align: middle;
  max-width: 100%;
}

.croppr {
  position: relative;
  display: inline-block;
  height: inherit;
  width: inherit;
}

.croppr-overlay {
  background: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: crosshair;
}

.croppr-region {
  border: 1px dashed rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 3;
  cursor: move;
  top: 0;
}

.croppr-image {
  height: inherit;
  width: inherit;
}

.croppr-imageClipped {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
  height: inherit;
  width: inherit;
}

.croppr-handle {
  border: 1px solid black;
  background-color: white;
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 4;
  top: 0;
}
