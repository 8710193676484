.vlv-sb-pane {
  background : rgba(0,0,0,.15);
  position : absolute;
  width : 10px;
  right : 0;
  top : 0;
  bottom : 0;
  opacity : .15; 
  -webkit-transition : .2s;
  -moz-transition : .2s;
  -o-transition : .2s;
  transition : .2s;
  -moz-border-radius : 5px;
  -webkit-border-radius : 5px;  
  border-radius : 5px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 2px;
}

.vlv-sb-active {
  opacity : 0.8;
}

.vlv-sb-hidden {
  display: none;
}

.vlv-sb-slider {
  height: 20px;
  top: 0px;
  background: #444;
  background: rgba(0,0,0,.5);
  position : relative;
  margin : 0 1px;
  -moz-border-radius : 3px;
  -webkit-border-radius : 3px;  
  border-radius : 3px;
}