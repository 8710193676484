.loadingContainer {
  display: flex;
}

.loadingSpinnerText {
  margin-left: 10px;
  color: #666666;
  position: relative;
  top: 3px;
}

.view-box-15 {
  height: 15px;
  width: 15px;
  animation: rotate-spinner 1000ms steps(8, end) infinite;
  position: relative;
  top: 3px;
}

@keyframes rotate-spinner {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}
