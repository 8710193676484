/* selectionListEx styles */

.slist-icon {
  display: block;
  float:left;
  clear:none;
  margin: 3px 10px 5px 5px;
}

.slist-icon-multi {
  margin: 3px 10px 5px 45px;
}

.slist-header {
  position: relative;
  /*float:left;*/
  clear:left;
  right: 0px;
  margin: 1px 0px 0px 0px;
  padding: 5px 5px 6px 5px;
  color: rgb(128, 128, 128);
  font-family: GibsonRegular;
  font-size: 14px;
}

.slist-item {
  position: relative;
  /*float:left;*/
  clear:left;
  right: 0px;
  margin: 1px 0px 0px 0px;
  padding: 5px 5px 6px 5px;
  border-radius: 3px;
  font-family: GibsonRegular;
  font-size: 14px;
  /*debug*/
  /*background-color:#cceeee;*/
}

.slist-item-custom {
  position: relative;
  /*float:left;*/
  clear:left;
  right: 0px;
  margin: 1px 0px 0px 0px;
  padding: 0px 0px 1px 1px;
  border-radius: 3px;
  font-family: GibsonRegular;
  font-size: 14px;
  /*debug*/
  /*background-color:#cceeee;*/
}

.slist-item-custom:hover {
}

.slist-horizontal-separator {
  position: relative;
  /*float:left;*/
  clear:left;
  margin: 10px 20px 10px 5px;
  border-bottom: 1px solid #ccc;
}

.slist-item-focused {
  background-color: rgb(240, 240, 240);
}

.slist-item:hover {
  background-color: rgb(240, 240, 240);
  cursor: pointer;
}

.slist-item-selected {
  background-color: rgb(128, 128, 128);
  color: rgb(255, 255, 255);
}

.slist-item-selected:hover {
  background-color: rgb(128, 128, 128);
  color: rgb(255, 255, 255);
}

.slist-checkbox {
  display: block;
  float:left;
  clear:none;
  margin-right: 15px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  min-height: 20px;
  width: 28px;
  /*height: 100%;*/
  padding: 6px 0px 0px 8px;
  border-radius: 3px 0px 0px 3px;
  /*debug*/
  /*background-color:#cceeee;*/
  /*border: 1px solid rgb(255, 0, 0);*/
}

.slist-checkbox:hover {
  background-color: rgb(232, 232, 232);
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.slist-separator {
  position: absolute;
  border-right: 1px solid #e8e8e8;
  top: -1px;
  bottom: 0px;
  left: 33px;
}

.slist-label {
  margin: 2px 0px 0px 0px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slist-label-multi {
  margin: 2px 0px 0px 45px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
