table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  border: 0;
  outline: 0;
  padding: 0;
  font-size: 100%;
  background: transparent;
}

.hide-it {
  display: none;
}

.feed__bold {
  font-family: "GibsonSemiBold", sans-seif;
}

.feed__container {
  /*width:100%;*/
  width: 560px;
  font-family: "GibsonRegular", sans-seif;
  overflow: auto;
}

.feed__old-feed-hide-dialog {
  cursor: pointer;
  color: #00bf64;
  font-family: "GibsonSemiBold", sans-seif;
  font-size: 14px;
}

.feed__old-feed-container {
  margin-top: 14px;
  border-top: 1px solid #e5e5e5;
  padding-top: 18px;
  padding-bottom: 200px;
}

.feed__old-feed-content-container {
  padding: 2px;
}

.feed__old-feed-details {
  color: #999999;
  font-family: "GibsonRegular", sans-seif;
  font-size: 14px;
}

.feed__old-feed-link {
  cursor: pointer;
  color: #00bf64;
  font-family: "GibsonSemiBold", sans-seif;
  font-size: 14px;
  line-height: 24px
}

.feed__old-feed-link:hover {
  color: #00bf64;
}

.feed {
  width: 530px;
  color: #111111;
  /* Responsive Sizing
   max-width: 600px;
   min-width: 240px;
   */
  background-color: #ffffff;
  margin: 0 auto 12px auto;
  padding: 12px;
  border-style: solid;
  border-color: #e0e0e0;
  border-top-color: #f2f2f2;
  border-bottom-color: #cccccc;
  border-width: 1px;
  border-radius: 1px;
}

.feed a {
  text-decoration: none;
}

.feed__body {
  padding-left: 32px;
}


/*Mobile Responsive Break Point*/

@media only screen and (max-width: 480px) {
  .feed__body {
    padding: 0px;
  }
}


/* ------------- */

.feed__header {
  height: 36px;
}

.feed__header a {
  color: #000;
  text-decoration: none;
}

.feed__avatar-actor {
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 8px;
  background-color: #eeeeee;
}

.feed__avatar-user {
  width: 36px;
  height: 36px;
  float: left;
  margin-right: 6px;
}

.feed__avatar-project {
  float: left;
  margin-right: 6px;
}

.feed__actor {
  font-size: 14px;
  font-family: GibsonSemiBold, sans-seif;
  line-height: 12px;
}

.feed__time {
  color: #B4B4B4;
  font-size: 12px;
  line-height: 16px;
}


/*MESSAGE*/

.feed__message {
  font-size: 14px;
  padding-bottom: 6px;
}

.feed__message a:link,
.feed__message a:visited {
  color: #007c3f;
  text-decoration: none;
}

.feed__message a:hover {
  color: #00bf64;
}


/* //// Project & Phase Styles //// */

.assignment-block {
  clear: both;
  width: 100%;
  font-size: 12px;
  min-height: 36px;
  line-height: 36px;
  margin-top: 6px;
  /* Optional Object Border */
  /*
    border-style:solid;
    border-color:#d4d4d4;
    border-width:1px;
    padding:2px;
    */
}

.assignment-block.remove {
  opacity: 0.5;
  filter: alpha(opacity=50)
}


/* BIG BAR STYLE ////////////// */


/*
  .assignment-block__schedule {
    height:36px;
    width:100%;
  }
  .assignment-block__assignment-range {
    font-size:12px;
    color:#4D4D4D;
    height: 14px;
    overflow:hidden;
    margin:0px;
    padding:0px;
  }
  .assignment-block--bar {
    overflow:auto;
    height: 36px;
    padding-left:6px;
    line-height:36px;
    color:#ffffff;
    margin:0px;
    border-radius: 4px;
  }
  .assignment-block--bar.confirmed {
    background: linear-gradient(to bottom, rgb(0,194,297) , rgb(0,149,208));

  }
  .assignment-block--bar.tentative {
    background: linear-gradient(to bottom, rgb(186,186,186) , rgb(142,142,142));
  }
  .assignment-block--bar.removed {
    background-color:#e6e6e6;
    color:#acacac;
  }
  .assignment-block--bar.phase {
    background-color:#515151;
  }
  */


/* ///////////////////////// */

.assignment-block--text {
  overflow: auto;
  min-height: 36px;
  line-height: 36px;
  color: #000;
}

span.assignment-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 16px;
  margin-top: -2px;
}

span.nobreak {
  display: inline-block;
}

.assignment-block__icon {
  /* full URL
    https://app.10000ft.com/images/sprite_notifications.png
    */
  width: 36px;
  height: 36px;
  background-image: url(/images/sprite_notifications.png);
  ;
  background-repeat: no-repeat;
  float: left;
  margin-right: 6px;
}

.assignment-block__icon.add-confirmed {
  background-position: 0px -64px;
}

.assignment-block__icon.add-tentative {
  background-position: 0px 0px;
}

.assignment-block__icon.add-internal {
  background-position: 0px -64px;
}

.assignment-block__icon.add-phase {
  background-position: 0px -256px;
}

.assignment-block__icon.confirmed {
  background-position: -256px -64px;
}

.assignment-block__icon.remove {
  background-position: 0px -448px;
}

.assignment-block__icon.delete {
  background-position: -128px -448px;
}

.assignment-block__icon.archive {
  background-position: -192px -448px;
}

.assignment-block__icon.date {
  background-position: -384px 0px;
}

.assignment-block__icon.allocation {
  background-position: -448px 0px;
}

.assignment-block__icon.vacation {
  background-position: -256px -448px;
}

.assignment-block__icon.task {
  background-position: 0px -320px;
  width: 16px;
  height: 16px;
  margin: 8px;
  float: left;
}

.assignment-block__icon-container img,
.assignment-block__icon-float img {
  position: absolute;
  border-style: none;
  top: 50%;
  left: 50%;
  margin: -12px 0px 0px -12px;
}


/*/////////////////////////*/


/* //// Project Object Styles //// */

.assignment-block__project {
  border-style: solid;
  border-color: #d4d4d4;
  border-width: 1px;
  padding: 1px;
}


/* //// Task-Specific Styles //// */

.assignment-block__object {
  height: 28px;
  margin-top: 6px;
  border-style: solid;
  border-color: #d4d4d4;
  border-width: 1px;
  padding: 6px;
  color: #000;
}

.assignment-block__object.closed {
  background-color: #f5f5f5;
  text-decoration: line-through;
  opacity: 0.5;
  filter: alpha(opacity=50)
}

.assignment-block__object.removed {
  opacity: 0.5;
  filter: alpha(opacity=50)
}

.assignment-block__object.active {}

.assignment-block__task {
  height: 28px;
  width: 100%;
}

.assignment-block__task--title {
  font-size: 14px;
  line-height: 28px;
}

.assignment-block__task--properties {
  float: right;
  font-size: 12px;
  color: #4D4D4D;
  line-height: 28px;
}

.assignment-block__task--properties > img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-left: 4px;
}

.assignment-block__task--properties > .task-group {
  width: 30px;
  height: 40px;
  background-image: url("/images/compSpritesButtonsIcons.png?18.0");
  background-position: -1097px -305px;
  background-repeat: no-repeat;
  top: -5px;
  position: relative;
  float: right;
  display: inline-block;
}

.assignment-block__task--due {
  font-size: 12px;
  color: #4D4D4D;
}

.hidden-filter {
  display: none !important;
}

.content-removed {
  color: #4D4D4D;
  opacity: .5;
}


/*/////////////////////////*/


/*Feed Action Buttons //////////////////////
  .feed__action-bar {
    margin-top:12px;
    font-size:12px;
    font-weight:600;
    display:inline-block;
  }
  .feed__action-bar a:link, a:visited {
    color:#8a8a8a;
    text-decoration:none;
  }
  .feed__action-bar a:hover {
    color:#00bf64;
    text-decoration:none;
  }
  */


/* Notification Buttons
  a.primary_button:link, a.primary_button:visited {
    background-color:#00bf64;
    border: 1px solid #00bf64;
    color:white;
    padding: 8px 16px 8px 16px;
    text-align:center;
    text-decoration:none;
    display:inline-block;
    border-radius: 4px;
  }
  a.secondary_button:link, a.secondary_button:visited {
    border: 1px solid #00bf64;
    color: #ff7744;
    padding: 8px 16px 8px 16px;
    text-align:center;
    text-decoration:none;
    display:inline-block;
    border-radius: 4px;
  }
  /////////////////////////////////////////*/