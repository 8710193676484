.availability-info {
    min-width: 300px;
    height: auto;
    border: 1px solid #e3e3e3;
    display: inline-block;
}

.availability-info .header,
.availability-info .row {
    clear: left;
    height: 15px;
}

.availability-info .header {
    color: #969696;
}

.availability-info .row {
    border-top: 1px solid #e3e3e3;
}

.availability-info .column {
    float: left;
    width: 20px;
    text-align: center;
    border-left: 1px solid #e3e3e3;
    padding: 4px 2px 4px 2px;
}

.availability-info .first {
    min-width: 118px;
    text-align: left;
    border-left: none;
    padding: 4px 2px 4px 6px;
}