a:link {
  color: #0970BE;
  text-decoration: none;
}

a:visited {
  color: #4C649D;
  text-decoration: underline;
}

a:hover {
  color:#0970BE;
  text-decoration: underline;
}

a:active {
  color: #4C649D;
  text-decoration: underline;
}

a:disabled {
  color: #979797;
}

a:focus {
  color: #0970BE;
  outline: #6097D2;
}

.pageHeader {
  width: 100%;
  min-width: 980px;
  height: 49px;
  overflow: hidden;
  text-align: left;
}

.pageHeader .searchBox {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border: none;
  color: #FFFFFF;
}

.pageHeader input.searchBox::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.pageHeader input.searchBox:focus::-webkit-input-placeholder {
  color: gray;
}

.pageHeader input.searchBox::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.pageHeader input.searchBox:focus::-moz-placeholder {
  color: gray;
}

/* IE */
.pageHeader input.searchBox:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.pageHeader input.searchBox:focus:-ms-input-placeholder {
  color: gray;
}

.pageHeader .searchBox.focus {
  background-color: #FFFFFF;
  color: #000;
}

.pageHeaderDevEnv {
  background-color: #933;
}

.pageHeaderLocalEnv {
  background-color: #339;
}

.pageHeaderInnerContainer {
  width: 100%;
  overflow: hidden;
  height: 49px;
}

.filterBar {
  width: 100%;
  height: 88px;
  background-color: #F2F2F2;
}

.well {
  width: auto;
  min-width: 100px;
  height: 32px;
  margin-top: 7px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
  border-radius: 32px;
  background-image: url(/images/darkCharcoalGradientReverse.png);
  background-size: 100% 100%;
}

.toggleButtonItem {
  color: #808080;
  font-family: GibsonRegular;
  font-size: 13px;
  line-height: 34px;
  margin-left: 13px;
  margin-right: 13px;
  cursor: pointer;
}

.toggleButtonItem.selected {
  color: #FFFFFF;
}

.menuButtonsLeft {
  margin-left: 14px;
  margin-right: 14px;
}

.menuButtonsRight {
  float: right;
  margin-left: 14px;
  margin-right: 14px;
}

.menuButtonItem {
  color: #FFFFFF;
  opacity: .7;
  line-height: 50px;
  text-align: center;
  text-shadow: 0px -1px 0px #000000;
}

.menuButtonItemLabel {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  color: #FFFFFF;
}

.trialButtonItemLabel {
  color: #FFFFFF;
}

.menuButtonItem.selected {
  color: #FFFFFF;
  opacity: 1;
  font-family: GibsonSemiBold;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lighterGreyButton {
  width: auto;
  height: 30px;
  line-height: 30px;
  color: #666666;
  /*text-shadow: 0px 1px 0px #FFFFFF;*/
  text-align: center;
  cursor: pointer;
}

.spriteButtonBase {
  width: auto;
  min-width: 60px;
  height: 30px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
  /*text-shadow:0px 1px 0px #333;*/
  cursor: pointer;
}

.spritesButtonTextContainer {
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
}

.spritesButtonCommonTextContainer {
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
  width: 100%;
}

.spritesButtonTextContainerWide {
  padding-right: 24px;
  padding-left: 24px;
}

.buttonContainerCenterWide {
  display: flex;
  justify-content: center;
  width: 100%;
}

.filtersButton {
  width: auto;
  padding-right: 6px;
  padding-left: 6px;
  height: 30px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-image: url(/images/darkGrayButtonGradient.png);
  background-size: 100% 100%;
  line-height: 30px;
  font-family: GibsonRegular;
  font-size: 14px;
  color: #FFFFFF;
  text-shadow: 0px -1px 0px rgba( 0, 0, 0, .5);
  text-align: center;
  cursor: pointer;
}

.checkboxContainer {
  cursor: pointer;
}

.checkboxBox {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  background-color: #FFFFFF;
  margin-left: 5px;
  margin-right: 5px;
}

.checkboxText {
  font-family: "GibsonRegular", Verdana, sans-serif;
  font-size: 14px;
  color: #000000;
}

#project-mute-status-control {
  float: right;
  font-family: "GibsonRegular", Verdana, sans-serif;
  font-size: 14px;
  cursor: pointer;
}

#project-mute-status-control:hover .projectMuteStatusPopup {
  display: block !important;
}

#project-mute-status-control .projectMuteStatusPopup {
  display: none;
  background-color: #FFFFFF;
  cursor: default;
  margin-top: 15px;
  position: absolute;
  text-align: left;
  top: 16px;
  right: -48px;
  width: 300px;
/*  height: 80px;*/
  z-index: 500;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0.2px 1.5px 2px 0.5px rgba(102, 102, 102, 0.45);
}

#project-mute-status-control .compressed {
  padding: 5px !important;
}

#project-mute-status-control > span {
  color: #999999;
  font-family: "GibsonRegular", Verdana, sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.projectMuteStatusPopup:before {
  content: "";
  position: absolute;
  width: 42px;
  height: 24px;
  right: 35px;
  top: -16px;
  background-image: url(/images/compSpritesButtonsIcons.png?19.0);
  background-repeat: no-repeat;
  background-position: -328px -656px;
}

#project-follow-control {
  float: right;
  font-family: "GibsonRegular", Verdana, sans-serif;
  font-size: 14px;
}

#project-follow-control:hover .projectFollowPopup {
  display: block !important;
}

#project-follow-control .projectFollowPopup {
  display: none;
  background-color: #FFFFFF;
  cursor: default;
  margin-top: 15px;
  position: absolute;
  text-align: left;
  top: 16px;
  right: -5px;
  width: 300px;
  z-index: 500;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0.2px 1.5px 2px 0.5px rgba(102, 102, 102, 0.45);
}
#project-follow-control .compressed {
  padding: 5px !important;
}

#project-follow-control > span {
  color: #999999;
  font-family: "GibsonRegular", Verdana, sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.projectFollowPopup:before {
  content: "";
  position: absolute;
  width: 42px;
  height: 24px;
  right: 35px;
  top: -16px;
  background-image: url(/images/compSpritesButtonsIcons.png?19.0);
  background-repeat: no-repeat;
  background-position: -328px -656px;
}

.projectSettingsLinkContainer {
  width: 94%;
  height: 100%;
  padding: 8px;
  cursor: pointer;
  background-color: transparent;
  overflow:hidden;
  text-align: center;
}

.projectSettingsLinkContainer:hover {
  background-color: #eeeeee;
}

.projectSettingsLinkContainer > span a {
  display: inline-block;
  color: #000000;
}

/* START CUSTOM SWITCH CSS */

span.switch-on {
  font-family: "GibsonRegular", Verdana, sans-serif;
  color: #00BF64 !important;
}

span.switch-off {
  font-family: "GibsonRegular", Verdana, sans-serif;
  color: #ADABAB !important;
}

.switch-info-text {
  font-family: "GibsonRegular", Verdana, sans-serif;
  font-size: 12px;
  color: #ADABAB;
  float: left;
}

.switch-info-container {
  margin-top: 8px;
}

h4.popup-label {
  font-family: "GibsonSemiBold", Verdana, sans-serif;
  margin: 0;
  opacity: .7;
}

.bell-icon-enabled {
  background-image: url(/images/bell.png);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  float: right;
  margin-left: 16px;
}

.bell-icon-disabled {
  background-image: url(/images/bell-slash.png);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  float: right;
  margin-left: 16px;
}

.onoffswitch {
  position: relative;
  width: 34px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  float: right;
  margin-left: 6px;
  cursor: pointer;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 1px solid #DFDFDF; border-radius: 14px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 14px; padding: 0; line-height: 14px;
    font-size: 14px; color: white;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "";
    padding-left: 10px;
    background-color: #00BF64; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "";
    padding-right: 10px;
    background-color: #CCCCCC; color: #333333;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 14px; margin: 0px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 18px;
    border: 1px solid #DFDFDF; border-radius: 14px;
    transition: all 0.2s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}


/* END CUSTOM SWITCH CSS */

.checkboxContainer.disabled .checkboxText {
  color: #808080;
}

.checkboxContainer.disabled .checkboxBox {
  background-color: #808080;
}

.filterLabel {
  font-family: "GibsonRegular", Verdana, sans-serif;
  color: #1A1A1A;
  font-size: 32px;
  /* -webkit-font-smoothing: antialiased; */
}

.showAllBtn {
  color: #7F7F7F;
  font-size: 14px;
  font-family: "GibsonRegular", Verdana, sans-serif;
}

.testBox {
  background-color: #1A1A1A;
  width: 20px;
  height: 20px;
  -moz-box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.30);
  -webkit-box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.30);
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.30);
  /* -moz-transform:skew(10deg,10deg) translate(-45px,-15px);
  -webkit-transform:skew(10deg,10deg) translate(-45px,-15px);
  transform:skew(10deg,10deg) translate(-45px,-15px); */
}

.dialog {
  background-color: #1A1A1A;
  width: 300px;
  height: 150px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.30);
  -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.30);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.30);
}

.testBoxRotate {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.dialogShadowRightBottomLeft {
  -moz-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.30), -4px 4px 8px rgba(0, 0, 0, 0.30), 0px 4px 8px rgba(0, 0, 0, 0.30);
  -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.30), -4px 4px 8px rgba(0, 0, 0, 0.30), 0px 4px 8px rgba(0, 0, 0, 0.30);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.30), -4px 4px 8px rgba(0, 0, 0, 0.30), 0px 4px 8px rgba(0, 0, 0, 0.30);
}

.boxModel {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.shortButton {
  line-height: 14px;
  padding: 4px 12px;
  font-size: 12px;
  display: inline-block;
  color: #ffffff;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-repeat: repeat-x;
}

.gradGreen {
  background-image: -moz-linear-gradient(top, #00d58b, #00ae5b);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00d58b), to(#00ae5b));
  background-image: -webkit-linear-gradient(top, #00d58b, #00ae5b);
  background-image: -o-linear-gradient(top, #00d58b, #00ae5b);
  background-image: linear-gradient(to bottom, #00d58b, #00ae5b);
  border: 1px solid #00ad65;
}

.gradBlue {
  background-image: -moz-linear-gradient(top, #00c8ed, #009bc7);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00c8ed), to(#009bc7));
  background-image: -webkit-linear-gradient(top, #00c8ed, #009bc7);
  background-image: -o-linear-gradient(top, #00c8ed, #009bc7);
  background-image: linear-gradient(to bottom, #00c8ed, #009bc7);
  border: 1px solid #009ac9;
}

.gradBlueNoBorder {
  background-image: -moz-linear-gradient(top, #00c8ed, #009bc7);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#00c8ed), to(#009bc7));
  background-image: -webkit-linear-gradient(top, #00c8ed, #009bc7);
  background-image: -o-linear-gradient(top, #00c8ed, #009bc7);
  background-image: linear-gradient(to bottom, #00c8ed, #009bc7);
}

.gradGrey {
  background-image: -moz-linear-gradient(top, #d5d5d5, #aeaeae);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#d5d5d5), to(#aeaeae));
  background-image: -webkit-linear-gradient(top, #d5d5d5, #aeaeae);
  background-image: -o-linear-gradient(top, #d5d5d5, #aeaeae);
  background-image: linear-gradient(to bottom, #d5d5d5, #aeaeae);
  border: 1px solid #adadad;
}

.feedbubble {
  width: 560px;
}

.feedItemThumbnailContainer {
  width: 60px;
  height: 60px;
  margin-top: 16px;
  overflow: hidden;
  cursor: pointer;
}

.feedItemThumbnail {
  width: 60px;
}

.feedItemCaptionContainerWrapper {
  margin-top: 16px;
  width: 470px;
}

.feedItemCaptionContainer {
  width: 470px;
  min-height: 50px;
}

.feedItemCaptionIcon {
  width: 50px;
  height: 50px;
}

.feedItemCaptionText {
  color: #000000;
  width: 383px;
}

.feedItemCaptionTime {
  color: #ACACAC;
  width: 383px;
  margin-top: 5px;
}

.feedItemCaptionTextContainer {
  margin-top: 2px;
  margin-left: 10px;
}

.userHeaderContainer {
  width: 600px;
  height: auto;
  margin-left: 20px;
  overflow: hidden;
  white-space: nowrap;
}

.userHeaderContainerLabel {
  width: 580px;
}

.darkHeaderText {
  color: #1A1A1A;
}

.medGrayHeaderText {
  color: #4D4D4D;
}

.mainContent {
  width: 100%;
  text-align: center;
}

.textInput {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #000000;
  background-color: #f5f5f5;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 7px;
  height: 30px;
}

.filterPopupTextInput {
  width: 235px;
}

.filterPopupAddObject {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.filterPopupAddObject:hover {
  background-color: #F2F2F2;
}

.personPageFloat {
  width: 1020px;
}

.personPageUserInfoContainer {
  width: 100%;
  text-align: center;
  background-color: #F2F2F2;
}

.personPageContentContainer {
  width: 980px;
  min-height: 400px;
  text-align: left;
  margin-left: 22px;
  margin-right: 22px;
}

.personPageMainContentArea {
  width: 560px;
  min-height: 20px;
}

.userProjectInfoContainer {
  width: 179px;
  min-height: 20px;
}

.personHeaderBarContainer {
  background-color: #000000;
  text-align: center;
}

.personHeaderBar {
  width: 980px;
  height: 50px;
  text-align: left;
}

#logoContainer {
  text-align: center;
  margin-top: 60px;
}

.plansHeaderLogo {
  margin: 13px 0px 0 21px;
  width: 24px;
  display: block;
  float: left;
}

.plansHeaderLogoCustom {
  float: left;
  margin: 4px;
}

.plansHeaderArrowDown {
  position: absolute;
}

.headerLogoIcon {
  margin-top: 5px;
  width: 200px;
}

.userInfoContainer {
  width: 980px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  background-color: #F2F2F2;
  margin-left: 22px;
  margin-right: 22px;
}

.userInfoThumbnailContainer {
  width: 181px;
  overflow: "hidden";
  margin-left: -2px;
}

.userInfoContentContainer {
  width: 600px;
  padding-left: 10px;
  padding-right: 10px;
}

.userInfoRightContainer {
  width: 180px;
}

.editProfileContainer {
  margin-top: 10px;
  width: 980px;
}

.editProfileButton {
  color: #1BA879;
  font-size: 13px;
  font-family: GibsonRegular;
}

.floatRightDiv {
  float: right;
}

.userThumbContainer {
  margin-left: 0px;
}

.userStatusContainer {
  width: 536px;
  margin-left: 210px;
  margin-top: 90px;
}

.userThumbnailFrameContainer {
  width: 186px;
  height: 186px;
  overflow: hidden;
  background-image: url('/images/user_thumb_frame.png');
}

.userThumbnailFrameImageContainer {
  width: 166px;
  height: 166px;
  overflow: hidden;
  margin-left: 9px;
  margin-top: 8px;
}

.userThumbnailFrameImage {
  width: 100%;
  max-width: 100%;
}

.currentProjectsContainer {
  width: 180px;
}

.projectThumbnailFrameContainerSmall {
  width: 180px;
  height: 110px;
  overflow: hidden;
  cursor: pointer;
}

.projectThumbnailFrameImageContainerSmall {
  width: 170px;
  height: 95px;
  overflow: hidden;
  margin-left: 5px;
  margin-top: 5px;
  background-color: #F2F2F2;
}

.projectThumbnailFrameContainer {
  width: 180px;
  height: 143px;
  overflow: hidden;
  cursor: pointer;
  margin-left: -1px;
}

.projectThumbnailFrameImageContainer {
  width: 160px;
  height: 90px;
  overflow: hidden;
  margin-left: 10px;
  margin-top: 10px;
}

.projectThumbnailFrameImage {
  width: 100%;
  max-width: 100%;
  margin-left: 2px;
}

.projectThumbnailFrameContainerLarge {
  width: 234px;
  height: 184px;
  overflow: hidden;
}

.projectThumbnailFrameImageContainerLarge {
  width: 214px;
  height: 120px;
  overflow: hidden;
  margin-left: 10px;
  margin-top: 10px;
  background-color: #F2F2F2;
}

.projectThumbnailFrameImageLarge {
  width: 100%;
  max-width: 100%;
}

.projectThumbTagNameLarge {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 1px;
  width: 182px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.projectThumbTagContainerLarge {
  margin-left: 0px;
  margin-top: 5px;
  height: 40px;
  overflow: hidden;
}

.projectThumbTagContainer {
  margin-left: 10px;
  margin-top: 5px;
}

.projectThumbTagName {
  color: #000000;
  margin-right: 5px;
  overflow-wrap: break-word;
}

.statusView {
  width: 536px;
  height: auto;
}

.statusIcon {
  width: 50px;
  height: 50px;
  margin-top: 3px;
}

.statusText {
  width: 476px;
  margin-left: 10px;
}

.statusTime {
  max-width: 393px;
  color: #ACACAC;
  margin-top: 10px;
  margin-bottom: 10px;
}

.statusTextLabel {
  color: #666666;
  padding-right: 5px;
}

.statusTextLabelBold {
  color: #1A1A1A;
  padding-right: 5px;
}

.userContactInfoContainer {
  width: 181px;
  height: 100%;
  min-height: 40px;
  margin-left: 0px;
}

.contactInfoContainer {
  width: 180px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}

.addToSlackContainer {
  width: 180px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}

.contactInfoTagContainer {
  margin-bottom: 13px;
}

.savedReportsOuterDiv {
  margin-bottom: 15px;
}

.savedReportsOuterDiv h2{
  font-weight: normal;
}

.savedReportContainer {
  min-height: 30px;
  width: 180px;
  margin-bottom: 5px;
}

.savedReportContainerWide {
  min-height: 30px;
  width: 560px;
}

.savedReportIconNoLeftMargin {
  margin-top: 5px;
  margin-right: 5px;
}

.savedReportIcon {
  margin-top: 5px;
  margin-left: 3px;
  margin-right: 9px;
}

.savedReportIconWide {
  margin-left: 3px;
  margin-right: 9px;
}

.savedReportContainer:hover {
  background-color: #F2F2F2;
}

.savedReportContainerWide:hover {
  background-color: #F2F2F2;
}

.savedReportSelected {
  min-height: 30px;
  width: 180px;
  background-color: #F2F2F2;
}

.savedReportSelectedWide {
  min-height: 30px;
  width: 560px;
  background-color: #F2F2F2;
}

.savedReportText {
  color: #000000;
  margin-top: 9px;
  margin-bottom: 9px;
  width: 115px;
  text-align: left;
}

.savedReportTextWide {
  color: #000000;
  margin-top: 9px;
  margin-bottom: 9px;
  width: 483px;
}

.lineSpacerSaveReport {
  height: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
  width: 100%;
  background-color: #d2d2d2;
}

.contactInfoTagName {
  color: #929292;
}

.contactInfoTagValue {
  color: #000000;
  overflow: normal;
  white-space: normal;
}

.spacer1 {
  height: 20px;
}

.headerText1 {
  margin-bottom: 13px;
  margin-top: 40px;
}

.headerText2 {
  margin-top: 40px;
}

.headerText3 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.pastText {
  color: #000000;
  width: 180px;
}

.addButton {
  height: 1px;
  padding-top: 15px;
  color: #000000;
  text-align: left;
}

.trackerContentArea {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 10px;
}

.popupHeaderDiv {
  margin-top: 10px;
  color: #808080;
  line-height: 15px;
  margin-left: 10px;
}

.activityViewContainer {
  width: 560px;
  text-align: left;
  margin-left: 1px;
}

#feeditemscontainer {
  margin-top: 0px;
}

#taskitemscontainer {
  margin-top: 20px;
  width: 560px;
}

.activityHeaderContainer {
  width: 560px;
  height: 20px;
}

.translationNotification {
  width: 560px;
  padding: 15px;
  margin-top: 32px;
  background: #FFF3CC;
  border: 1px solid #E3D6AB;
}

.activityViewSeparator {
  width: 560px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.activityViewFilterButton {
  width: 100px;
  margin-top: 11px;
  cursor: pointer;
}

.activityViewButtonShow {
  color: #4D4D4D;
}

.activityViewButtonLabel {
  color: #000000;
}

.activityDropDownSelect {
  margin-left: 3px;
  margin-top: 18px;
  margin-right: 0px;
  width: 10px;
  height: 5px;
  cursor: pointer;
}

.editStatusContainer {
  width: 560px;
  border: transparent;
  text-align: left;
  margin-top: 10px;
}

.editStatusCodeContainer {
  width: 265px;
  height: 33px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  line-height: 30px;
  color: #666666;
  cursor: pointer;
}

.editStatusCodeImage {
  margin-left: 0px;
  margin-top: 3px;
  width: 24px;
  height: 24px;
}

.editStatusCodeValue {
  color: #000000;
  overflow: hidden;
  margin-left: 10px;
  width: 170px;
  text-align: left;
}

.editStatusCodePopupContainer {}

.editStatusDropDownSelect {
  margin-left: 3px;
  margin-top: 12px;
  width: 10px;
  height: 5px;
}

.editStatusLabelOn {
  color: #7F7F7F;
  text-align: center;
  width: 30px;
  vertical-align: middle;
  margin-top: 12px;
}

.editStatusLabelDoing {
  color: #7F7F7F;
  text-align: center;
  height: 33px;
  vertical-align: middle;
  margin-top: 12px;
  padding-right: 12px;
}

.editStatusProjectContainer {
  width: 265px;
  height: 33px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  line-height: 30px;
  color: #666666;
  cursor: pointer;
  overflow: hidden;
  border: 0px solid #781351
}

.editStatusProjectValue {
  color: #000000;
  margin-left: 10px;
  width: 225px;
  text-align: left;
}

.editStatusDoingContainer {
  margin-top: 5px;
  width: 560px;
  height: 30px;
  text-align: left;
}

.editStatusDoingInput {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #000000;
  height: 30px;
  width: 490px;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 7px;
}

.editStatusNoteInput {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #a6a6a6;
  height: 55px;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 7px;
  padding-top: 6px;
  margin-top: 9px;
  width: 549px;
  resize: none;
}

.editStatusCancelButton {
  padding-right: 17px;
  padding-left: 17px;
  margin-right: 10px;
}

.editStatusPostButtonContainer {
  margin-top: 5px;
  width: 560px;
  height: 30px;
  text-align: right;
}

.iconImage {
  margin-left: 2px;
  margin-top: 3px;
  width: 24px;
  height: 24px;
}

.iconDropDownSelect {
  margin-left: 3px;
  margin-top: 12px;
  margin-right: 10px;
  width: 10px;
  height: 5px;
}

.iconDropDownSelect2 {
  margin-left: 3px;
  margin-top: 7px;
  margin-right: 10px;
  width: 10px;
  height: 5px;
}

.calendarDayCell {
  width: 31px;
  height: 28px;
  margin-left: -1px;
  margin-bottom: -1px;
  color: #1A1A1A;
  cursor: pointer;
  line-height: 30px;  
}


.calendarButtonLabel {
  width: 31px;
}

.calendarDayCellDisabled {
  width: 31px;
  height: 28px;
  margin-left: -1px;
  margin-bottom: -1px;
  color: #666666;
  cursor: pointer;
  line-height: 30px;
}

.calendarDayCellSelected {
  width: 31px;
  height: 26px;
  margin-top: 1px;
  margin-left: -1px;
  margin-bottom: 0px;
  color: #1A1A1A;
  cursor: pointer;
  line-height: 30px;
}

.calendarDayNamesContainer {
  color: #1A1A1A;
  cursor: default;
  margin-bottom: 5px;
}

.calendarDayName {
  width: 30px;
  height: 12px;
  text-align: center;
}

.calendarPopupContainer {
  background-color: #FFFFFF;
  text-align: center;
  width: 230px;
  overflow: hidden;
}

.calendarContainer {
  width: 217px;
  background-color: #f2f2f2;
  margin: 10px;
}

.calendarHeader {
  cursor: default;
  text-align: center;
  margin-top: -4px;
  margin-bottom: 8px;
  width: 145px;
}

.calendarYearContainer {
  width: 100%;
}

.calendarYear {
  padding-right: 8px;
  margin-bottom: 8px;
}

.calendarLeftMonth {
  cursor: pointer;
  width: 30px;
  margin-bottom: 5px;
}

.calendarRightMonth {
  cursor: pointer;
  width: 30px;
  margin-bottom: 5px;
}

.calendarDayHolder {
  margin-bottom: 8px;
}

.confirmationPopupContainer {
  display: flex;
  flex-direction: column;
}

.confirmationPopupLabelContainer {
  overflow: hidden;
}

.confirmationPopupButtonContainer {
  row-gap: 8px;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  flex: 1;
}

.confirmationAvailabilityPopupButtonContainer {
  row-gap: 8px;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding-bottom: 4px;
  flex: 1;
}

.confirmationPopupCancelButton {
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.confirmationPopupMiddleButton {
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.confirmationPopupYesButton {
  padding-right: 10px;
  padding-left: 10px;
}

.defaultTextInputContainerSmall {
  width: 255px;
  min-height: 30px;
  max-height: 45px;
}

.defaultTextInputSmall {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  color: #000000;
  background-color: #f5f5f5;
  height: 30px;
  text-align: left;
  padding-left: 7px;
  width: 235px;
}

.defaultInputTextInputSmall {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #000000;
  background-color: #f5f5f5;
  height: 30px;
  text-align: left;
  padding-left: 7px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 7px;
  width: 235px;
}

.defaultTextInputLabel {
  color: #666666;
  margin-left: 2px;
  margin-bottom: 2px;
}

input.file {
  width: 185px;
  height: 30px;
  color: #FFFFFF;
  background-color: #5f5f5f;
  font-size: 14px;
}

button {
  width: 85px;
  height: 30px;
  color: #FFFFFF;
  background-color: #5f5f5f;
  font-size: 14px;
}

.collapseLeftButton {
  z-index: 8;
  min-width: 10px;
  height: 20px;
}

.expandLeftButton {
  z-index: 8;
  min-width: 10px;
  height: 20px;
  top: -3px;
}

.todayButton {
  z-index: 7;
  background-color: #ebebeb;
  font-family: GibsonRegular;
  font-size: 14px;
  color: #444444;
  cursor: pointer;
  margin-top: 5px;
  height: 22px;
  width: 45px;
  padding-left: 7px;
  align-content: center;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.createAssignmentBtn {
  padding-left: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: center;
  cursor: pointer;
  z-index: 7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 15px;
  height: 24px;
  border-radius: 4px;
  font-family: GibsonRegular;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}

.createAssignmentBtn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.createAssignmentBtn:active {
  background-color: rgba(0, 0, 0, 0.10);
}

.todayButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.todayButton:active {
  background-color: rgba(0, 0, 0, 0.10);
}

.expandCollapseAllBtn {
  font-family: GibsonRegular;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: absolute;
  right: 16px;
  bottom: 0;
  transform: translateY(-6px);
  padding: 0;
  margin: 0;
  width: 76px;
  height: 20px;
}

.expandCollapseAllBtn:hover{
  background-color: rgb(235, 235, 235);
}

.plusIcon {
  z-index: 7;
  margin-top: 3px;
  padding-left: 3px;
}

.awsChooseThumbButton {
  width: auto;
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
}

/* aws croppr styles */
.croppr-region {
  border: 4px solid #FFFFFF;
}

.croppr-handle {
  border: 1px solid #808080;
  border-radius: 2px;
  width: 10px;
  height: 10px;
}

.chooseCertificateButton {
  width: auto;
  margin-top: 2px;
  padding-right: 10px;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
}

.personThumbnailContainer {
  width: 54px;
  height: 54px;
  margin-top: 9px;
  overflow: hidden;
}

.personThumbnailContainerSpacer {
  width: 9px;
  height: 54px;
  margin-top: 9px;
  overflow: hidden;
}

.personThumbnailImage {
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}

.personThumbnailOverlay {
  position: absolute;
  background-color: #eee;
  padding: 4px;
  width: 54px;
  height: 54px;
  top: 0px;
  left: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.oobeContainer {
  width: 990px;
  margin-top: 20px;
}

.oobeButtonRight {
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 15px;
  margin-bottom: 3px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  min-width: 60px;
}

.reportDetailedTimeContainer {
  margin-top: 30px;
  height: auto;
}

.reportHeaderInfo {
  color: #878787;
  width: 103px;
  height: 49px;
  position: relative;
}

.reportHeaderInfoName {
  color: #878787;
  width: 190px;
  height: 49px;
  position: relative;
}

.reportHeaderInfoNameWide {
  color: #878787;
  width: 294px;
  height: 49px;
  position: relative;
}

.reportHeaderInfoContainer {
  height: 49px;
}

.reportDetailedTimeNameColumn {
  width: 180px;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  position: absolute;
  bottom: 7px;
  right: 10px;
}

.reportDetailedTimeNameColumnWide {
  width: 284px;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  position: absolute;
  bottom: 7px;
  right: 10px;
}

.reportDetailedTimeInfoColumn {
  width: 63px;
  text-align: right;
  line-height: 15px;
  vertical-align: bottom;
  position: absolute;
  white-space: normal;
  overflow: hidden;
  bottom: 7px;
  right: 10px;
}

.reportDetailedTimeInfoDataColumn {
  width: inherit;
  text-align: right;
  line-height: 15px;
}

.reportStringInfoDataColumn {
  width: 136px;
  text-align: left;
  line-height: 15px;
  margin-left: 10px;
}

.reportDetailedTimeInfoColumnLight {
  color: #878787;
  width: inherit;
  text-align: right;
}

.reportDataRow {
  min-height: 40px;
  width: 560px;
}

.reportDataName {
  width: 220px;
  height: 30px;
  margin-top: 9px;
  overflow: hidden;
  white-space: nowrap;
}

.reportDataNameWidthNarrow {
  width: 190px;
  min-height: 30px;
  margin-top: 9px;
  overflow: hidden;
  white-space: nowrap;
}

.reportDataNameShortBudgetIndent {
  width: 210px;
  min-height: 20px;
  margin-top: 9px;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 10px;
}

.reportDataNameShort {
  width: 220px;
  min-height: 20px;
  margin-top: 9px;
  overflow: hidden;
  white-space: nowrap;
}

.reportDataNameShortNarrow {
  width: 190px;
  min-height: 20px;
  margin-top: 9px;
  overflow: hidden;
  white-space: nowrap;
}

.reportDataNameWide {
  width: 264px;
  min-height: 30px;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.reportDataNameNarrow {
  width: 117px;
  min-height: 30px;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.reportDataColumnLabel {
  width: 70px;
  min-height: 33px;
  position: relative;
  margin-top: 7px;
  margin-right: 3px;
}

.reportDataColumnLabelShort {
  width: 70px;
  min-height: 23px;
  position: relative;
  margin-top: 10px;
  margin-right: 3px;
}

.reportDataColumn {
  width: 100px;
  min-height: 33px;
  position: relative;
  margin-top: 7px;
  margin-right: 3px;
}

.reportDataColumnString {
  width: 146px;
  min-height: 25px;
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
  overflow: visible;
  white-space: normal;
}

.reportDataColumnShort {
  width: 100px;
  min-height: 23px;
  position: relative;
  margin-top: 10px;
  margin-right: 3px;
}

.reportDataColumnStringShort {
  width: 161px;
  min-height: 15px;
  height: auto;
  position: relative;
  margin-top: 10px;
  margin-bottom: 7px;
  overflow: visible;
  white-space: normal;
}

.reportHeaderToggle {
  width: 230px;
}

.reportToggleButton {
  margin-top: 19px;
  width: 50px;
  min-width: 50px;
}

.reportSpacer5 {
  width: 220px;
  height: 10px;
}

.reportColumnSpacer {
  width: 1px;
  height: 33px;
}

.reportColumnSpacerSprite {
  width: 1px;
  overflow: hidden;
}

.timeEntryInputContainer {
  text-align: left;
  margin-left: 12px;
  margin-top: 0px;
}

.timeEntryInputHeaderLabel {
  color: #999999;
  margin-bottom: 2px;
}

.timeEntryTaskColumn {
  width: 150px;
}

.timeEntryNotesColumn {
  width: 150px;
}

.timeEntryHoursColumn {
  width: 54px;
}

.timeEntrySpacer1 {
  width: 8px;
  height: 8px;
}

.timeEntryAddIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.timeEntryAddLineContainer {}

.timeEntryAddLine {
  cursor: pointer;
  line-height: 24px;
  margin-left: 8px;
  width: 104px;
}

.timeEntryTotal {
  line-height: 24px;
  text-align: right;
  width: 170px;
}

.expenseTimeFilterSpacer {
  width: 125px;
  overflow: hidden;
  margin-top: 3px;
  margin-bottom: 3px;
  height: 1px;
  background-color: #e5e5e5;
  margin-left: 16px;
  margin-right: 16px;
}

.timeEntryImage1 {
  width: 364px;
  overflow: hidden;
  margin-top: 2px;
  margin-bottom: 10px;
}

.timeEntryTotalValue {
  line-height: 24px;
  text-align: right;
  margin-left: 8px;
  width: 54px;
  text-align: center;
}

.fullScreenOverlay {
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 999;
  min-height: 100%;
  height: auto;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

.fullScreenOverlayFade {
  opacity: 0;
}

.fullScreenOverlayBackground {
  width: 100%;
  background-color: #000000;
  position: absolute;
  top: 0px;
  opacity: 0.0;
  min-height: 100%;
  height: auto;
}

.wizardBackground {
  opacity: 0.8;
}

.wizardFrameOuter {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 680px;
  min-height: 100%;
  height: auto;
}

.wizardFrame {
  display: inline-block;
  position: relative;
  border-radius: 5px;
  width: 600px;
  min-height: 300px;
  background-color: #fff;
  padding: 20px 40px 20px 40px;
}

.wizardLeftColumn {
  width: 340px;
  margin-right: 20px;
  min-height: 230px;
}

.wizardRightColumn {
  border-radius: 2px;
  width: 220px;
  padding: 0px 10px 0px 10px;
  background-color: #ededed;
}

.wizardErrorBox {
  border-radius: 2px;
  width: 580px;
  padding: 10px 10px 10px 10px;
  margin-bottom: 20px;
  background-color: #ededed;
  color: #c00;
  display: none;
}

.wizardExpiryContainer {
  width: 160px;
  margin: 15px 0px 15px 0px;
}

.wizardCvvContainer {
  width: 140px;
  margin: 15px 0px 15px 0px;
}

.wizardBillingZipContainer {
  width: 140px;
  margin: 0px 0px 15px 0px;
}

.wizardCouponHelpText {
  width: 340px;
  margin: 15px 0px 15px 0px;
}

.wizardPlanSummaryText {
  margin-top: 10px;
  margin-bottom: 10px;
}

.wizardButtons {
  margin-top: 15px;
}

.wizardButton {
  width: auto;
  min-width: 60px;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  float: right;
}

.wizardDottedLine {
  height: 1px;
  border-top: 1px dotted #ccc;
  width: 100%;
}

.wizardSolidLine {
  height: 1px;
  border-top: 1px solid #ccc;
  width: 100%;
}

.wizardTitle {
  width: 100%;
  margin-bottom: 5px;
}

.wizardSubTitle {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 20px;
}

.wizardDescription {
  line-height: 15px;
  margin-bottom: 20px;
}

.wizardLargePlan {
  line-height: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 30px;
}

.wizardWeAccept {
  line-height: 20px;
  margin-right: 10px;
}

.wizardCcBanner {
  margin: 0px 0px 15px 0px;
}

.wizardVspacer35px {
  width: 100%;
  height: 35px;
}

.wizardVspacer15px {
  width: 100%;
  height: 15px;
}

.wizardSelectionRow {
  padding: 15px 0px 0px 0px;
  border-top: 1px dotted #ccc;
  width: 100%;
}

.wizardSelectionRowText {
  line-height: 15px;
  margin-bottom: 15px;
}

.wizardYearlySavingsExplanation {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.progressSpinnerContent {
  border: none;
  border-radius: 7.5px;
  background: #000;
  opacity: 1.0;
  width: 400px;
  height: 44px;
  -webkit-box-shadow: 0px 0px 41px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 41px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 41px 5px rgba(0, 0, 0, 0.25);
}

.spinnerImage {
  margin-left: 10px;
  margin-top: 10px;
}

.spinnerImagePageHeader {
  margin: 12px 0px 0px;
}

.multiSelectPopupRow {
  width: 200px;
  height: 30px;
}

.multiSelectPopupLabelHover {
  background-color: #eeeeee;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.multiSelectPopupIconContainer {
  height: 30px;
  width: 42px;
  cursor: pointer;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-right: 3px;
}

.multiSelectPopupIconContainer:hover {
  background-color: #eeeeee;
}

.multiSelectPopupIcon {
  margin-top: 8px;
  margin-left: 14px;
}

.multiSelectPopupSeperator {
  width: 1px;
  height: 30px;
  background-color: #e8e8e8;
}

.multiSelectPopupLabel {
  width: 139px;
  line-height: 30px;
  color: #1A1A1A;
  margin-left: 3px;
  padding-left: 12px;
  cursor: pointer;
}

.multiSelectPopupLabel:hover {
  background-color: #eeeeee;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.deleteButtonCategory {
  margin-left: 10px;
  margin-top: 4px;
  width: 22px;
  height: 22px;
  cursor: pointer
}

.deleteButtonCategoryPhase {
  margin-left: 10px;
  margin-top: 4px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  opacity: 0;
}

.deleteButtonDisabled {
  cursor:default;
}

.deleteButtonCategoryPhase:focus {
  opacity: 1;
}

.editprojectMenuItem:focus ~ .deleteButtonCategoryPhase {
  opacity: 1;
}

.editprojectWrap:hover .deleteButtonCategoryPhase {
  opacity: 1;
}

.editprojectWrap:hover {
  background: #eeeeee;
}

.cancelButtonNotification {
  width: 22px;
  height: 22px;
}

.cancelButtonAddTask {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  margin-top: 5px;
}

.projectStatusContainerLink {
  overflow: hidden;
}

.projectStatusViewLabel {
  margin-top: 10px;
}

.projectStatusViewLabel2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.projectStatusViewPhase {
  margin-bottom: 3px;
  margin-top: 5px;
  max-width: 165px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.projectStatusViewPhaseIcon {
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.spanLink {
  color: #1BA879;
  cursor: pointer;
}

.reportFilterItemHeader {
  margin-top: 25px;
  color: #878787;
}

.reportFilterItemValue {
  line-height: 30px;
  width: 200px;
  height: auto;
  cursor: pointer;
  white-space: normal;
  margin-left: -10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.reportFilterItemValueMultiline {
  margin-top: 6px;
  margin-bottom: 6px;
  line-height: 18px;
  width: 200px;
  height: auto;
  cursor: pointer;
  white-space: normal;
  margin-left: -10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.reportFilterItemValueText {
  margin-left: 10px;
}

.reportFilterItemValueFiltering {
  line-height: 30px;
  width: 200px;
  margin-left: -10px;
  background-color: #f2f2f2;
  white-space: normal;
  cursor: pointer;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #bfbfbf;
}

.subMenuListItem {
  width: 100%;
  height: 30px;
  background-color: Transparent;
  line-height: 30px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
}

.subMenuListItem:hover {
  background-color: #bfbfbf;
}

.highContrast .reportFilterItemValueFiltering {
  background-color: #f2f2f2;
}

.reportFilterItemValueMultilineFiltering {
  margin-top: 6px;
  margin-bottom: 6px;
  line-height: 18px;
  width: 200px;
  height: auto;
  cursor: pointer;
  white-space: normal;
  margin-left: -10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #bfbfbf;
}

.highContrast .reportFilterItemValueMultilineFiltering {
  background-color: #f2f2f2;
}

.reportFilterItemValue:hover {
  background-color: #bfbfbf;
}

.reportFilterItemValueMultiline:hover {
  background-color: #bfbfbf;
}

.highContrast .reportFilterItemValueMultiline:hover,
.highContrast .reportFilterItemValue:hover {
  background-color: #f2f2f2;
}

.pageLogo {
  width: 142px;
  height: 158px;
  margin-right: 67px;
  margin-left: 66px;
  margin-bottom: 40px;
}

.widthTester {
  position: absolute;
  float: left;
  white-space: nowrap;
  visibility: hidden;
}

.staticListItem:hover {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #F2F2F2;
}

.labelSelect {
  cursor: pointer;
}

.taskFilterContainer {
  margin-top: 15px;
  width: 560px;
}

.taskFilterSubContainer {
  height: 14px;
  width: 560px;
}

.taskAddTask {
  cursor: pointer;
  margin-left: 5px;
}

.taskGroupingMenu {
  margin-left: 20px;
}

.taskGroupByLabel {
  color: #878787;
}

.taskGroupBySelected {
  cursor: normal;
}

.taskGroupByUnselected {
  cursor: pointer;
}

.taskFilterButton {
  height: 14px;
  text-align: right;
  height: auto;
  cursor: pointer;
  margin-left: 10px;
  min-width: 90px;
}

.taskButtonShow {
  color: #878787;
}

.taskButtonLabel {
  color: #000000;
}

.taskDropDownSelect {
  margin-left: 3px;
  margin-right: 0px;
  margin-top: 4px;
  width: 10px;
  height: 5px;
  cursor: pointer;
}

.taskAddLabel {
  color: #7F7F7F;
  margin-top: 8px;
  margin-left: 7px;
  overflow: hidden;
  white-space: nowrap;
  width: 520px;
}

.taskTextInput {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #000000;
  background-color: #f5f5f5;
  height: 30px;
  width: 541px;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 6px;
  margin-left: 7px;
  margin-top: 2px;
}

.taskCommentContainer {
  width: 536px;
  margin-left: 24px;
  margin-top: 5px;
  height: auto;
}

.taskCommentContainerMe {
  width: 536px;
  margin-left: 24px;
  margin-top: 5px;
  height: auto;
  cursor: pointer;
}

.taskCommentContainerMe:hover {
  background-color: #f5f5f5;
}

.taskCommentContainerMeHover {
  width: 536px;
  margin-left: 24px;
  margin-top: 5px;
  height: auto;
  background-color: #f5f5f5;
  cursor: pointer;
}

.taskCommentEditContainer {
  width: 536px;
  margin-left: 24px;
  height: auto;
}

.commentThumbnailContainer {
  width: 50px;
  height: 50px;
  overflow: hidden;
  cursor: pointer;
}

.commentThumbnailImage {
  width: 100%;
  max-width: 100%;
}

.commentSpacerLine {
  height: 1px;
  margin-top: 5px;
  overflow: hidden;
  width: 536px;
}

.commentLabelContainer {
  width: 460px;
  margin-left: 12px;
  height: auto;
  word-break: break-all;
}

.taskCommentTextInput {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #000000;
  background-color: #f5f5f5;
  height: 30px;
  width: 527px;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 6px;
  margin-left: 24px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.taskEditTextInput {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #000000;
  background-color: #f5f5f5;
  height: 30px;
  width: 427px;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 5px;
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.taskCommentEditTextInput {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #000000;
  background-color: #f5f5f5;
  height: 30px;
  width: 403px;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 5px;
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.taskGroupHeader {
  margin-top: 10px;
  line-height: 40px;
}

.taskCommentRowViewContainer {
  width: 542px;
  margin-top: 5px;
  height: auto;
}

.tasksGroupHeaderContainer {
  width: 560px;
}

.taskRowViewContainer {
  width: 560px;
  height: auto;
  cursor: pointer;
}

.taskRowEditContainer {
  width: 560px;
  height: auto;
}

.taskRowViewContainerEmpty {
  background-color: #f5f5f5;
  width: 560px;
  height: 40px;
}

.taskRowViewContainerHover {
  width: 560px;
  height: auto;
  background-color: #f5f5f5;
}

.taskRowViewContainer:hover {
  background-color: #f5f5f5;
}

.taskRowViewBullet {
  margin-top: 12px;
  margin-left: 3px;
  min-width: 10px;
}

.taskRowViewBulletDrag {
  margin-top: 8px;
  margin-left: 3px;
  min-width: 10px;
}

.taskEmptyGroupLabel {
  color: #878787;
  margin-top: 10px;
}

.taskRowViewLabel {
  margin-top: 11px;
  margin-left: 11px;
  max-width: 430px;
  margin-bottom: 13px;
}

.taskRowViewClosedLabel {
  text-decoration: line-through;
}

.tasksSpacerLine {
  height: 1px;
}

.tasksButtonsContainer {
  overflow: hidden;
  white-space: nowrap;
  max-height: 34px;
  margin-bottom: 6px;
  margin-right: 5px;
}

.tasksButtonsContainerMenu {
  overflow: hidden;
  white-space: nowrap;
  max-height: 34px;
  margin-bottom: 10px;
}

.taskThumbnailFrameImageContainer {
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}

.taskThumbnailFrameImageContainerMenuGroup {
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-top: -4px;
  margin-left: 10px;
}

.taskThumbnailFrameImageContainerActivity {
  width: 20px;
  height: 14px;
  height: auto;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  line-height: 15px;
  margin-top: 4px;
  margin-left: 10px;
}

.taskThumbnailFrameImageContainerComment {
  width: 24px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  line-height: 38px;
}

.taskThumbnailFrameImageContainerGroup {
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.taskThumbnailFrameImageContainerMenu {
  width: 25px;
  height: 25px;
  overflow: hidden;
  margin-top: 2px;
  margin-left: 5px;
}

.taskThumbnailFrameImage {
  width: 100%;
  max-width: 100%;
}

.taskSecondaryButtonCategory {
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  min-width: 60px;
}

.taskCancelButtonCategory {
  margin-left: 5px;
  margin-top: 5px;
  width: 20px;
  height: 20px;
}

.taskAddTaskGroupHeader {
  margin-top: 12px;
  line-height: 38px;
}

.tasksAddTaskIconGroup {
  margin-top: 26px;
  margin-right: 2px;
  cursor: pointer;
}

.tasksAddTaskIcon {
  margin-top: 2px;
  margin-right: 2px;
  cursor: pointer;
}

.commonSelectorIcon {
  width: 20px;
  margin-right: 5px;
  margin-left: 1px;
}

.commonSelectorText {
  line-height: 16px;
}

.taskIconMenu {
  margin-left: 10px;
  margin-top: 10px;
}

.taskIconMenuContainer {
  width: 30px;
  height: 30px;
}

.taskIconMenuContainer:hover {
  background-color: #f5f5f5;
}

.taskIconMenuItem {
  margin-top: 8px;
  margin-left: 10px;
}

.projectContextMenuContainer {
  height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  overflow: hidden;
}

.projectContextMenuSectionLabel {
  color: #666666;
  margin-left: 10px;
  margin-right: 15px;
}

.projectContextMenuNameLabel {
  margin-left: 10px;
  margin-right: 15px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 25px); /* width of container - margins */
}

.projectContextMenuTypeLabel {
  margin-left: 10px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 45px); /* width of container - width of icon */
}

.projectContextMenuTypeContainer {
  width: 100%;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  height: 30px;
  cursor: pointer;
}

.projectContextMenuTypeContainer2 {
  margin-top: 2px;
  margin-bottom: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.projectContextMenuTypeContainer:hover {
  background-color: #f5f5f5;
}

.projectContextMenuTypeContainer2:hover {
  background-color: #f5f5f5;
}

.projectContextMenuSpacerLine {
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  height: 1px;
}

.projectContextMenuSpacerLine2 {
  width: auto;
  height: 5px;
}

.projectContextMenuIcon {
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 8px;
}

.projectContextMenuIcon2 {
  margin-left: 10px;
  margin-top: 2px;
}


/* css rounded border with nipple, based on http://bit.ly/RSKum8 */

.triangle-border-left {
  position: relative;
  padding: 5px;
  border: 1px solid #e9e9e9;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 5px;
  margin-left: 16px;
  margin-right: 2px;
}

.triangle-border-left:before {
  content: "";
  position: absolute;
  top: 18px;
  bottom: auto;
  left: -16px;
  border-width: 12px 16px 12px 0;
  border-color: transparent #ccc;
  border-style: solid;
  display: block;
  width: 0;
}

.triangle-border-left:after {
  content: "";
  position: absolute;
  top: 19px;
  bottom: auto;
  left: -15px;
  border-width: 11px 15px 11px 0;
  border-color: transparent #fff;
  border-style: solid;
  display: block;
  width: 0;
}

.statusLineSpacer {
  width: 1;
  height: 10px;
  overflow: hidden;
}

.filterMenuHeader {
  padding-left: 10px;
  width: 240px;
  padding-top: 3px;
  padding-bottom: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  line-height: 24px;
  color: #999999;
}

.filterMenuIcon {
  padding-right: 10px;
}

.filterMenuItem {
  padding-left: 10px;
  width: 240px;
  padding-top: 3px;
  padding-bottom: 3px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
}

.griddeleteButtonCategory {
  margin-left: 10px;
  padding-top: 3px;
  width: 22px;
  height: 22px;
  cursor: pointer
}

.filterMenuItem:hover {
  background-color: #eeeeee;
}

.filterMenuItemSelected {
  padding-left: 10px;
  width: 240px;
  padding-top: 3px;
  padding-bottom: 3px;
  line-height: 24px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #FFFFFF;
  cursor: pointer;
  background-color: #808080;
}

.filterMenuItem:active {
  background-color: #808080;
  color: #FFFFFF;
}

.filterNoSaved {
  padding-left: 10px;
  width: 240px;
  padding-top: 3px;
  padding-bottom: 3px;
  line-height: 24px;
  color: #A0A0A0;
}

.gridFilterLinespacer {
  height: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 10px;
  width: 230px;
  background-color: #d2d2d2;
}

.gridFilterEditInputContainerSmall {
  width: 280px;
  height: 45px;
  margin-top: 15px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.gridFilterTextInputLabel {
  color: #929292;
  margin-left: 2px;
  margin-bottom: 2px;
}

.gridFilterInputSmall {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #000000;
  background-color: #eeeeee;
  height: 30px;
  width: 270px;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 7px;
}

.expensesControlContentContainer {
  margin-top: 20px;
  text-align: left;
  width: 560px;
  white-space: nowrap;
}

.expenseHeaderLabel {
  width: 560px;
  white-space: normal;
  margin-top: 10px;
}

.expensesControlHeaderContainer {
  width: 270px;
  overflow: hidden;
}

.expensesControlHeaderContainerLight {
  overflow: hidden;
  color: #666666;
  margin-top: 10px;
  margin-bottom: 5px;
}

.expenseAddIconGroup {
  margin-top: 3px;
  margin-right: 2px;
  cursor: pointer;
}

.expenseAddGroupHeader {
  line-height: 16px;
  margin-bottom: 10px;
}

.expenseNoExpensesLabel {
  color: #7F7F7F;
  line-height: 28px;
}

.expenseAddPrimaryButton {
  margin-left: 8px;
}

.expenseTotalLabel {
  width: 278px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 35px;
  min-height: 1px;
  margin-left: 8px;
}

.expenseTotal {
  line-height: 30px;
}

.availabilityDescription {
  width: 560px;
  margin-top: 10px;
  overflow: hidden;
  white-space: normal;
}
.lockDescription {
  width: 560px;
  margin-top: 20px;
  overflow: hidden;
  white-space: normal;
}

.availabilityInputDateText {
  height: 30px;
  min-width: 85px;
}

.availabilityInputContainer {
  width: 115px;
  height: 30px;
  line-height: 30px;
  color: #666666;
  cursor: pointer;
  text-align: left;
  padding-left: 7px;
  color: #000000;
}

.availabilityEditInputContainerSmall {
  width: 115px;
  height: 45px;
  margin-top: 15px;
}

.availabilityTextInputLabel {
  color: #666666;
  margin-left: 2px;
  margin-bottom: 2px;
}

.availabilitySpacer1 {
  width: 10px;
  height: 45px;
}

.availabilityInputContainerDay {
  width: 23px;
  height: 30px;
  line-height: 30px;
  color: #666666;
  cursor: pointer;
  text-align: left;
  padding-left: 7px;
  color: #000000;
}

.availabilityEditInputContainerDay {
  width: 30px;
  height: 45px;
  margin-top: 15px;
}

.availabilityInputDay {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #000000;
  background-color: #f5f5f5;
  height: 26px;
  width: 27px;
  text-align: center;
  border: 1px solid #e5e5e5;
  padding-left: 0px;
}

.availabilityControlContentContainer {
  text-align: left;
  width: 560px;
}

.availabilityAddIcon {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.availabilityDeleteIcon {
  margin-top: 34px;
  margin-left: 4px;
  width: 24px;
  height: 24px;
}

.allocationInputContainer {
  margin-top: 0px;
}

.allocationSelector {
  display: flex;
  width: calc(100% - 55%);
  height: 25px;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 10px;
}

.allocationSelectorText2 {
  line-height: 18px;
}

.allocationSelectorIcon {
  width: 20px;
  margin-right: 10px;
}


/* end css rounded border */

.gaBeaconIFrame {
  width: 1px;
  height: 1px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-style: none;
}

.gradient_lightgray {
  background: -webkit-linear-gradient(rgb(233, 233, 233), rgb(228, 228, 228));
  background: -moz-linear-gradient(rgb(233, 233, 233), rgb(228, 228, 228));
  background: -o-linear-gradient(rgb(233, 233, 233), rgb(228, 228, 228));
  background: linear-gradient(rgb(233, 233, 233), rgb(228, 228, 228));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#e1e1e1', endColorstr='#d5d5d5')";
}

.tag {
  height: 30px;
  border-radius: 3px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(233, 233, 233);
  white-space: nowrap;
}

.tagText {
  margin-top: 6px;
  margin-left: 8px;
  margin-right: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
}

.removeIcon {
  margin-top: 4px;
  margin-right: 2px;
  width: 22px;
  height: 22px;
  background-image: url(/images/compSpritesButtonsIcons.png?18.0);
  background-position: -880px -335px;
  background-repeat: no-repeat;
  opacity: 1.0;
  cursor: pointer;
}

.tagRemoveIcon:hover {
  opacity: 1.0;
}

.formContentButton {
  border: none;
  background-color: #EEEEEE;
  color: #444444  ;
  /*text-shadow: 0px 1px 0px #FFFFFF;*/
  text-align: center;
  height: 30px;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
}

.formContentButton:hover {
  background-color: #E0E0E0;
}

.formContentButton:active {
  background-color: #C0C0C0;
}

.addTagButton {
  width: 240px;
  margin-top: 20px;
  margin-left: -10px;
}

.formDescriptionStart {
  margin-top: 10px;
}

.customFieldsContainer {
  margin-bottom: 13px;
}

.customFieldName {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #929292;
}

.customFieldValue {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: #000000;
}

.editCustomFieldsLineSpacer {
  width: 10px;
  height: 30px;
  margin-top: 15px;
}

.editCustomFieldEditInputContainer {
  width: 275px;
  height: 45px;
  margin-top: 15px;
}

.editCustomFieldTextInputLabel {
  color: #666666;
  margin-left: 2px;
  margin-bottom: 2px;
}

.editCustomFieldInputSmall {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #000000;
  background-color: #f5f5f5;
  height: 26px;
  width: 255px;
  text-align: left;
  border: 1px solid #e5e5e5;
  padding-left: 7px;
  margin-right: 10px;
}

.editCustomFieldPopupInputButton {
  width: 268px;
  height: 34px;
  line-height: 30px;
  color: #666666;
  cursor: pointer;
  text-align: left;
  padding-left: 7px;
  color: #000000;
}

.editCustomFieldPopupInput {
  color: #000000;
  background-color: transparent;
  height: 30px;
  text-align: left;
  border: 0px solid #f5f5f5;
  outline: none;
  max-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tk-box {
  -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin-top: 20px;
  padding: 40px;
}

.tk-box__header {
  font-family: 'GibsonSemiBold';
  color: #000000;
  /* $tk-green */
  margin: 0 0 20px 0;
}

.tk-box__message {
  color: #666666;
}

.tk-box .tk-button {
  margin: 30px auto 0 auto;
  display: block;
}

.tk-button {
  width: auto;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 10px;
  height: 30px;
  font-size: 14px;
  font-family: 'GibsonRegular';
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.tk-button:focus {
  outline: none;
}

.tk-button--primary {
  background-color: #3576BA;
  color: #FFFFFF;
}

.tk-button--blue {
  background-color: #0073EC;
}

.tk-button--disabled {
  cursor: default;
  opacity: 0.5;
}

.buttonDisabled {
  pointer-events: none;
}

.buttonEnabled {
  pointer-events: auto;
}

.projectStatusSpinner {
  position: relative;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.loadingProjectsSpinner {
  position: relative;
  text-align: right;
  color: #878787;
  top: -2px;
  margin-left: 10px;
}

.loadingProjectsSpinner::after  {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4) 1000ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to { width: 1.25em; }
}

.projectStatusSpinner IMG {
  margin-left: 10px;
  margin-top: 10px;
}

.accountSwitcher {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 8px;

}

.settingsBrandingPickerTextRight {
  margin: 0 0 0 10px;
  float: right;
}

.settingsBrandingPickerTextLeft {
  margin: 24px 0 0 0;
  float: left;
  width: 100%;
  font-weight: bold;
}

.settingsBrandingPickerInput {
  width: 38px;
  height: 38px;
  float: left;
  margin: 8px 0 0 0;
}

.settingsBrandingPickerTextInput {
  width: 74px;
  height: 38px;
  float: left;
  margin: 8px 0 0 0;
}

.settingsBrandingPreview {
  height: 49px;
  width: 560px;
  overflow: hidden;
  text-align: left;
  margin: 16px 0 0 0;
}

.settingsBrandingPreviewInner {
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: start;
}

.headerLogoCustomImage {
  width: inherit;
  height: inherit;
}

.editBrandDarkText {
  color:#000000;
  margin-top:8px;
  width: 100%;
}

.editBrandEditThumbnailText {
  text-align:left;
  margin-top: 32px;
}

.editBrandMenuButtonItemLabel {
  margin-left: 10px;
  margin-right: 10px;
}
