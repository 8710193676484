#accessNewFeatures {
  position: relative; 
}

#accessNewFeatures .tooltip {
  background-color: #FFFFFF;
  cursor: default;
  margin-top: 15px;
  position: fixed;
  text-align: left;
  top: 40px;
  z-index: 10;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0.2px 1.5px 2px 0.5px rgba(102, 102, 102, 0.45);
  font-size: 14px; 
  color: #003059;
  width: 300px;
  line-height: 25px;
  text-shadow: none;
}

/* Tooltip arrow */
#accessNewFeatures .tooltip::before {
  content: "";
  position: absolute;
  width: 42px;
  height: 24px;
  top: -16px;
  background-image: url(/images/compSpritesButtonsIcons.png?19.0);
  background-repeat: no-repeat;
  background-position: -328px -656px;
}

#accessNewFeatures:hover {
  opacity: 1;

  .tooltip {
    visibility: visible; 
  }
}

.opaque {
  opacity: 1;
}

.hidden {
  visibility: hidden;
}