.splashWrapper {
  display: block;
  max-height: 40px;
}

.splash {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 744px; /* NOTE smallest min-width from `benchpress-client` efforts */
  height: 40px;
  background-color: rgb(139, 139, 139);
  color: #ffffff;
  max-height: 40px;
}

.splashContentWrapper {
  display: flex;
  min-width: 0; /* NOTE hack for flex children */
  margin: 0;
}

.splashIcon {
  flex: none;
  margin-left: 10px;
  width: 30px;
  height: 40px;
  background-image: url(/images/compSpritesButtonsIcons.png?18.0);
  background-position: -1132px -305px;
  background-repeat: no-repeat no-repeat;
}

.splashMessage {
  margin-top: 12px;
  margin-left: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.splashBar {
  flex: none;
  margin: 12px 10px 0px 10px;
  color: #ccc;
}

.splashMoreLink {
  flex: none;
  margin-top: 12px;
}

.splashDismissWrapper {
  flex: none;
  margin: 7px 10px 5px 10px;
  padding: 4px 4px 6px 4px;
  background-color: rgb(139, 139, 139);
  border: 1px solid rgb(139, 139, 139);
  border-radius: 4px;
  cursor: pointer;
}

.splashDismissWrapper:hover {
  background-color: #bababa;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#bababa), to(#575757));
  background-image: -webkit-linear-gradient(top, #bababa, #575757);
  background-image: -moz-linear-gradient(top, #bababa, #575757);
  background-image: -ms-linear-gradient(top, #bababa, #575757);
  background-image: -o-linear-gradient(top, #bababa, #575757);
  background-image: linear-gradient(to bottom, #bababa, #575757);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#bababa, endColorstr=#575757);
}

.splashDismissLink {
  float: left;
  margin: 0px 0px 0px 5px;
}

.splashDismissIcon {
  float: left;
  margin-top: 1px;
  margin-left: 5px;
  width: 14px;
  height: 14px;
  background-image: url(/images/compSpritesButtonsIcons.png?18.0);
  background-position: -1154px -398px;
  background-repeat: no-repeat no-repeat;
}

.splash a {
  color: #ffffff;
}

.splash a:hover {
  color: #ececfe;
}
