@import '../../variables.css.scss';

.gridMenu__row-id-option {
  display: flex;
  position: relative;
  right: 6px;
  white-space: nowrap;
  overflow: hidden;
}

.gridMenu__row-id-option--disabled {
  display: flex;
  position: relative;
  right: 6px;
  white-space: nowrap;
  overflow: hidden;

  cursor: default;
  color: $tk-header-text-color;
}

.gridMenu__row-id-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  top: 2px;
  margin-right: 6px;
  display: flex;

  svg {
    fill: $tk-header-text-color;
    margin: 0 !important;
  }
}
