.gridDescribeWorkPopup {
  padding: 4px;
  height: 100%;
}
 
.gridDescribeWorkPopup input {
  border: 1px solid #e5e5e5;
  background: #F8F8F8;
  resize: none;
  width: 100%;
  padding: 2px;
  margin-bottom: 2px;
  color: #111111;
  height: 30px;
  border-radius: 2px;
}

.gridDescribeWorkPopup input:focus:enabled {
  outline: none;
  border: solid 1px #24A2EB;
}

.gridDescribeWorkPopup > div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  row-gap: 10px;
  width: 100%;
}

#viewDetailsId a {
  color: #444444;
}

#viewDetailsId a:hover {
  color: #444444;
  text-decoration: none;
}

#viewDetailsId:hover {
  background: #E0E0E0;
  text-decoration: none;
  color: #444444;
}

#viewDetailsId{
  border-radius: 3px;
  height: 30px;
  text-align: center;
  background: #EEEEEE  ;
  border: none;
  cursor: pointer;
  color: #444444;
}

.gridDescribeWorkPopup button {
  border-radius: 2px;
  height: 30px;
  text-align: center;
  background: #3576BA;
  border: none;
  cursor: pointer;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gridDescribeWorkPopup button:hover {
  background: #6097D2;
}

.gridDescribeWorkPopup button:active {
  background: #4C649D;
}