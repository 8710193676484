.resourceColorLabel {
  color: #929292;
  margin-left: 2px;
  margin-bottom: 2px;
  margin-top: 10px;
}

.resourceColorBorder {
  width: 30px;
  height: 30px;
  cursor: pointer;  
  border: solid 1px #D3D3D3;
  border-radius: 2px;
}

.resourceColorElement {
  border-radius: 2px;
  width: 26px;
  height: 26px;
  margin-top: 1px;
  margin-bottom: 2px;
  margin-right: 1px;
  margin-left: 1px;
}
