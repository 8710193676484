@import '../../variables.css.scss';

.gridWorkItemStatusPopup {
  max-height: 262px;
  overflow: auto;
}

.gridWorkItemStatusPopup ul {
  padding-left: 0;
  margin-left: 0;
  margin-top: 10px;
}

.gridWorkItemStatusPopup li {
  padding: 4px 2px 4px 4px;
  margin: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  margin-right: 8px;
  &.no-options {
    color: $tk-header-text-color;
    cursor: text;
    &:hover {
      background: #fff !important;
    }
  }
}

.gridWorkItemStatusPopup li:not(.status-item__selected):hover {
  background-color: #f5f5f5;
}

.scheduleStatusPopup__icon {
  height: 24px;
  min-width: 24px;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  margin-right: 8px;
}

.scheduleStatusPopup__text {
  max-width: calc(100% - 32px); // width of container - icon width
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scheduleStatusPopup__label {
  font-family: GibsonRegular;
  font-size: 14px;
  color: $tk-header-text-color;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scheduleStatusPopup__blue_bright   { background-color: $work_status_blue_bright; }
.scheduleStatusPopup__gray          { background-color: $work_status_gray; }
.scheduleStatusPopup__red           { background-color: $work_status_red; }
.scheduleStatusPopup__yellow        { background-color: $work_status_yellow; }
.scheduleStatusPopup__green_bright  { background-color: $work_status_green_bright; }
.scheduleStatusPopup__blue_dark     { background-color: $work_status_blue_dark; }
.scheduleStatusPopup__purple        { background-color: $work_status_purple; }
.scheduleStatusPopup__orange        { background-color: $work_status_orange; }
.scheduleStatusPopup__green_dark    { background-color: $work_status_green_dark; }

.status-item__selected, .status-item__selected:hover {
  background-color: $tk-background-color-medium;
  color: #fff;
  font-weight: bold;
}

.gridMenu__status-option {
  display: flex;
  position: relative;
  right: 6px;
  white-space: nowrap;
  overflow: hidden;
  span:first-child {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    top: 2px;
    margin-right: 6px;
  }
}
