/* Max Width and Width are dynamically injected via inline styling for localization */
.gridItemHoverPopup {
  display: block;
  position: fixed;
  text-align: left;
  background: #fff;
  z-index: 100;
  height: auto;
  color: #111;
  padding: 12px;
  transition-property: opacity;
  transition-timing-function: ease;
  opacity: 0;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
  font-family: 'GibsonRegular', 'Helvetica', 'Verdana', sans-serif;
}

.gridItemHoverPopup table {
  margin-top: 12px;
  font-weight: 400;
}

.gridItemHoverPopup h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 4px 0;
}

.gridItemHoverPopup h4 {
  font-size: 14px;
  font-weight: 400;
  margin: 6px 0;
  line-height: 1.35;
}

.gridItemHoverPopup td {
  padding-top: 3px;
  padding-bottom: 3px;
  vertical-align: top;
}

.gridItemHoverPopup__description {
  /* For Firefox */
  white-space: pre-wrap;
  word-break: break-all;

  /* For Chrome and IE */
  word-wrap: break-word;
}

.gridItemHoverPopup__label {
  color: #808080;
  font-size: 12px;
}

.gridItemHoverPopup__value {
  font-size: 12px;
}

.gridItemHoverPopup__container {
  margin: 12px 0;
}

.gridItemHoverPopup__statusIcon {
  border-radius: 2px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  padding: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block
}

.gridItemHoverPopup__statusIcon__blue_bright {
  background-color: #92D4F4;
}

.gridItemHoverPopup__statusIcon__gray {
  background-color: #E0E0E0;
}

.gridItemHoverPopup__statusIcon__red {
  background-color: #E81A56;
}

.gridItemHoverPopup__statusIcon__yellow {
  background-color: #FAC905;
}

.gridItemHoverPopup__statusIcon__green_bright {
  background-color: #00bf64;
}

.gridItemHoverPopup__statusIcon__blue_dark {
  background-color: #24A2EB;
}

.gridItemHoverPopup__statusIcon__purple {
  background-color: #C285FF;
}

.gridItemHoverPopup__statusIcon__orange {
  background-color: #FF8533;
}

.gridItemHoverPopup__statusIcon__green_dark {
  background-color: #007C3F;
}

.gridItemHoverPopup__statusIcon__dark_grey {
  background-color: #5C5C5C;
}

.gridItemHoverPopup__statusIcon__gray {
  background-color: #E0E0E0;
}

