.vlv-container {
    position: absolute;
    overflow-x: hidden;
    overflow-y: hidden;
    /*debug*/
    /*background-color: #fee;*/
}

.vlv-content {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /*debug*/
    /*background-color: #eef;*/
    -moz-user-select: none; 
    -khtml-user-select: none; 
    -webkit-user-select: none; 
    -o-user-select: none; 
    -ms-user-select: none;
    user-select: none;
}

.vlv-item {
    display: block;
    float:left;
    clear:left;
}