#authorizedApplications {
  width: 100%;
  color: #1A1A1A;
  font: Gibson;
}

table.authorizedApplications {
  width: 100%;
}

table.authorizedApplications th {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}

#authorizedApplications p {
  color: #666666;
}

table.authorizedApplications tbody tr:not(.authorizedApplicationsHeader) {
  border-bottom: 1px solid #F2F2F2;
  border-top: 1px solid #F2F2F2;
  border-collapse: collapse;
  vertical-align: baseline;
}

table.authorizedApplications td {
  border: none;
  padding: 0.5em 0;
}

table.authorizedApplications td strong {
  font-weight: 600;
  color: #1A1A1A;
  font-weight: bold;
}

.revokeAuthorizedApp {
  background: #d33045;
  border-radius: 2px;
  color: #fff;
  padding: 4px 8px;
}

.revokeAuthorizedApp:hover {
  background: #e14b68;
}
