/*
 * Font styles are named with generic names so that we can substitue a stylesheet
 * with locale specific font faces.
 *
 * Style Key:
 *
 *   |-------------------------------------------------------------------------|
 *   |  .fnt       |  r,b,i,bi         |  nn             |  *                  |
 *   |-------------|-------------------|---------------------------------------|
 *   |  prefix     |  r = regular      |  font-size      |  ls = light shadow  |
 *   |             |  b = bold         |                 |  ds = dark shadow   |
 *   |             |  i = italic       |                 |                     |
 *   |             |  bi = bold italic |                 |                     |
 *   |-------------------------------------------------------------------------|
 *
 */

 .fnt-r-10 {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 10px;
}

.fnt-b-10 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 10px;
}

.fnt-r-11 {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-b-11 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-r-11-ls {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 12px;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.75);
}

.fnt-b-11-ls {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 12px;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.75);
}

.fnt-r-11-ds {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 12px;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
}

.fnt-b-11-ds {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 12px;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
}

.fnt-r-12 {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-b-12 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-r-12-ds {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 12px;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
}

.fnt-b-12-ds {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-r-12-ws {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-b-12-ws {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-r-13 {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-b-13 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-r-13-ls {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-b-13-ls {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 12px;
}

.fnt-r-14 {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-b-14 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-r-14-ls {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 14px;
    text-shadow: 0px 1px 0px rgba(202, 202, 202, 0.75);
}

.fnt-b-14-ls {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-r-14-ds {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-b-14-ds {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-r-14-ws {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-b-14-ws {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-r-15 {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-b-15 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 14px;
}

.fnt-r-18 {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 18px;
}

.fnt-b-18 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 18px;
}

.fnt-r-20 {
  font-family:GibsonRegular, Verdana, sans-serif;
  font-size:20px;
}

.fnt-b-20 {
  font-family:GibsonSemiBold, Verdana, sans-serif;
  font-size:20px;
}

.fnt-r-24 {
  font-family:GibsonRegular, Verdana, sans-serif;
  font-size:24px;
}

.fnt-b-24 {
  font-family:GibsonSemiBold, Verdana, sans-serif;
  font-size:24px;
}

.fnt-r-26 {
  font-family:GibsonRegular, Verdana, sans-serif;
  font-size:26px;
}

.fnt-b-26 {
  font-family:GibsonSemiBold, Verdana, sans-serif;
  font-size:26px;
}

.fnt-b-30 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 30px;
}

.fnt-r-32 {
    font-family: GibsonRegular, Verdana, sans-serif;
    font-size: 32px;
}

.fnt-b-32 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 32px;
}

.fnt-b-41 {
    font-family: GibsonSemiBold, Verdana, sans-serif;
    font-size: 40px;
}